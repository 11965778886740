<template>
  <div>
    <div class="row pt-5">
      <div class="col-xxl-5">
        <h5 class="font-weight-bold text-dark">
          Beschreibung
        </h5>
      </div>
      <div class="col-xxl-3 text-right">
        <h5 class="font-weight-bold text-dark">
          Anzahl
        </h5>
      </div>
      <div class="col-xxl-2 text-right">
        <h5 class="font-weight-bold text-dark">
          Preis CHF
        </h5>
      </div>
      <div class="col-xxl-2 text-right">
        <h5 class="font-weight-bold text-dark">
          Total CHF
        </h5>
      </div>
    </div>
    <div class="border-bottom mb-5" />

    <invoice-position-line
      v-if="subscriptionOptions.has_logo_added"
      description="Firmenlogo hinzugefügt"
      :single-price="formatFloat(subscriptionOptions.logo_price)"
      :total-price="formatFloat(subscriptionOptions.total_logo_price)"
    />
    <invoice-position-line
      v-if="subscriptionOptions.has_branch_offices_added"
      description="Niederlassungen hinzugefügt"
      :count="subscriptionOptions.nr_of_branch_offices_added"
      :single-price="formatFloat(subscriptionOptions.branch_office_price)"
      :total-price="formatFloat(subscriptionOptions.total_branch_offices_price)"
    />
    <invoice-position-line
      v-if="subscriptionOptions.has_additional_bkp_added"
      description="Zusätzliche BKP Nummern hinzugefügt"
      :count="subscriptionOptions.nr_of_additional_bkp_added"
      :single-price="formatFloat(subscriptionOptions.additional_bkp_price)"
      :total-price="formatFloat(subscriptionOptions.total_additional_bkp_price)"
    />
    <invoice-position-line
      v-if="subscriptionOptions.has_portrait_added"
      description="Firmenportrait hinzugefügt"
      :single-price="formatFloat(subscriptionOptions.portrait_price)"
      :total-price="formatFloat(subscriptionOptions.total_portrait_price)"
    />

    <invoice-summary-line
      description="Total hinzugefügte Optionen"
      :total-price="formatFloat(subscriptionOptions.total_options_price)"
    />

    <invoice-header-line
      description="Beschreibung"
      first="Anzahl"
      second="Preis CHF"
      third="Total CHF"
    />

    <div class="border-bottom mb-5" />

    <invoice-position-line
      description="Listenpreis"
      :count="subscriptionOrder.nr_of_years + ' Jahre'"
      :single-price="formatFloat(subscriptionOptions.total_options_price)"
      :total-price="formatFloat(subscriptionOptions.total_list_price)"
    />
    <div class="border-bottom mb-5" />

    <invoice-header-line
      description="Rabatte"
      first="Rabatt"
      second="Ersparnis CHF"
      third="Total CHF"
    />

    <div class="border-bottom mb-5" />

    <invoice-position-line
      description="Rabatt aus Abonement"
      :count="formatFloat(subscriptionOptions.total_discount_percentage) + ' %'"
      :single-price="
        formatFloat(
          subscriptionOptions.total_list_price -
            subscriptionOptions.total_discount_price
        )
      "
      :total-price="formatFloat(subscriptionOptions.total_discount_price)"
    />

    <div class="border-bottom mb-5" />

    <invoice-header-line
      description="Verbleibende Laufzeit Tage"
      first="Total"
      second="Verbleibend"
      third="Total %"
    />
    <div class="border-bottom mb-5" />

    <invoice-position-line
      description="Verbleibende Vertragslaufzeit"
      :count="subscriptionOptions.total_subscription_duration + ' Tage'"
      :single-price="
        subscriptionOptions.remaining_subscription_duration + ' Tage'
      "
      :total-price="
        formatFloat(
          subscriptionOptions.remaining_subscription_duration_percentage,
          4,
          false
        )
      "
    />

    <div class="row pt-5">
      <div class="col-xxl-9">
        <h5 class="font-weight-bold text-dark">
          BETRAG GESCHULDET CHF
        </h5>
      </div>
      <div class="col-xxl-3 text-right">
        <h5 class="font-weight-bold text-dark">
          {{ formatFloat(subscriptionOptions.invoice.invoice_amount) }}
          CHF
        </h5>
      </div>
    </div>
    <div class="border-bottom mb-5" />
  </div>
</template>
<script>
import { utilMixin } from "@/mixins/utilMixin";
import InvoiceHeaderLine from "@/components/Invoice/InvoiceHeaderLine";
import InvoicePositionLine from "@/components/Invoice/InvoicePositionLine";
import InvoiceSummaryLine from "@/components/Invoice/InvoiceSummaryLine";

export default {
  name: "InvoiceSubscriptionOptionsDetails",
  mixins: [utilMixin],
  components: { InvoiceHeaderLine, InvoicePositionLine, InvoiceSummaryLine },
  props: {
    subscriptionOptions: {
      required: true
    },
    subscriptionOrder: {
      required: true
    }
  }
};
</script>
