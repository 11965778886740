<template>
  <div v-if="isLoaded">
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <!--            <div class="border-bottom mt-10" />-->
            <invoice-header :invoice="subscriptionOptionsOrder.invoice" />

            <invoice-subscription-options-details
              :subscription-options="subscriptionOptionsOrder"
              :subscription-order="subscriptionOrder"
            />

            <invoice-vat-tax :invoice="subscriptionOptionsOrder.invoice" />

            <div class="mb-10" />
            <!--begin: Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2"></div>
              <div>
                <a
                  v-bind:href="
                    subscriptionOptionsOrder.invoice.user_invoice_pdf
                  "
                  class="btn btn-success font-weight-bold text-uppercase px-9 py-4 mr-4"
                  data-wizard-type="action-submit"
                  target="_blank"
                >
                  Rechnung als PDF herunterladen
                </a>
                <button
                  class="btn btn-danger font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-submit"
                  :disabled="
                    subscriptionOptionsOrder.invoice.invoice_status == 'PAID'
                  "
                >
                  Rechnung jetzt bezahlen
                </button>
              </div>
            </div>
            <!--end: Actions -->
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import {
  LOAD_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER,
  LOAD_DASHBOARD_SUBSCRIPTION_ORDER
} from "@/store/dashboard.module";
import InvoiceHeader from "@/components/Invoice/InvoiceHeader";
import InvoiceSubscriptionOptionsDetails from "@/components/Invoice/InvoiceSubscriptionOptionsDetails";
import InvoiceVatTax from "@/components/Invoice/InvoiceVatTax";

export default {
  name: "SubscriptionOptionsInvoice",
  components: {
    InvoiceSubscriptionOptionsDetails,
    InvoiceHeader,
    InvoiceVatTax
  },
  mixins: [utilMixin],
  data() {
    return {
      subscriptionOptionsOrder: {},
      subscriptionOrder: {},
      subscriptionOrderId: this.$route.params.orderId,
      subscriptionOptionsId: this.$route.params.optionsId,
      isLoaded: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await Promise.all([
        this.$store.dispatch(LOAD_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER, {
          orderId: this.subscriptionOrderId,
          optionsId: this.subscriptionOptionsId
        }),
        this.$store.dispatch(LOAD_DASHBOARD_SUBSCRIPTION_ORDER, {
          id: this.subscriptionOrderId
        })
      ]).then(() => {
        this.subscriptionOptionsOrder = this.getDashboardSubscriptionOptionsOrder;
        this.subscriptionOrder = this.getDashboardSubscriptionOrder;
      });
      this.isLoaded = true;
    }
  },
  computed: {
    ...mapGetters([
      "getDashboardSubscriptionOptionsOrder",
      "getDashboardSubscriptionOrder"
    ])
  }
};
</script>
